import { OPS_API_BASE_URL } from '@/services/connections.service';
import { getURLPath } from '@/services/http.service';

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getOpsAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: OPS_API_BASE_URL, pathname, pathParams });

export const ADMIN_GOOGLE_LOGIN_URL = () =>
  getOpsAPIURL('/auth/google?state=admin');

export const VERIFY_TOKEN_URL = getOpsAPIURL('/auth/verify');

export const GET_PARTNERS_URL = (skip, limit, sortField, sortOrder, filters) =>
  getOpsAPIURL(
    '/partners?skip=:skip&limit=:limit&sortField=:sortField&sortOrder=:sortOrder&filters=:filters',
    {
      skip,
      limit,
      sortField,
      sortOrder,
      filters
    }
  );

export const GET_CATEGORIES_URL = (
  skip,
  limit,
  optionsValue,
  searchQuery = ''
) =>
  getOpsAPIURL(
    '/categories?skip=:skip&limit=:limit&searchQuery=:searchQuery&field=:optionsValue',
    {
      skip,
      limit,
      searchQuery,
      optionsValue
    }
  );

export const GET_CORE_VERTICAL_CATEGORIES_URL = (
  isActive,
  includeSoftCategories
) =>
  getOpsAPIURL(
    '/core-vertical?isActive=:isActive&includeSoftCategories=:includeSoftCategories',
    { isActive, includeSoftCategories }
  );

export const CREATE_PARTNER_URL = getOpsAPIURL('/create-partner');

export const GET_PARTNER_BY_ID_URL = (partnerId) =>
  getOpsAPIURL('/partners/:partnerId/view', { partnerId });

export const UPDATE_PARTNER_URL = (id) =>
  getOpsAPIURL('/update-partners/:id', { id });

export const GET_PRODUCTS_URL = ({ skip, limit, filters }) =>
  getOpsAPIURL('/products?skip=:skip&limit=:limit&filters=:filters', {
    skip,
    limit,
    filters
  });

export const GET_PRODUCTS_BY_CORE_VERTICAL_SLUG_FOR_EXPORT = (categorySlug) =>
  getOpsAPIURL(
    '/products/categories/:categorySlug/export?categorySlug=:categorySlug',
    { categorySlug }
  );

export const GET_OPSUSERS_URL = ({ skip, limit, filters }) =>
  getOpsAPIURL('/opsusers?skip=:skip&limit=:limit&filters=:filters', {
    skip,
    limit,
    filters
  });

export const CREATE_OPSUSER_URL = getOpsAPIURL('/opsusers');

export const GET_PRODUCT_BY_ID = (id) => getOpsAPIURL('/products/:id', { id });

export const GET_CHILD_CATEGORIES_FOR_CORE_VERTICAL_URL = (parentSlug) =>
  getOpsAPIURL('/core-vertical/child-categories?parentSlug=:parentSlug', {
    parentSlug
  });

export const UPDATE_PRODUCT_BASIC_DETAILS = (productId) =>
  getOpsAPIURL('/products/:productId', { productId });

export const UPDATE_PRODUCT_ATTRIBUTES = (productId) =>
  getOpsAPIURL('/products/:productId/details', { productId });

export const DELETE_PRODUCT_IMAGE = (productId) =>
  getOpsAPIURL('/products/:productId/productMedia', { productId });

export const UPDATE_PRODUCT_IMAGE_SORT_ORDER = (productId) =>
  getOpsAPIURL('/products/:productId/image-sort-order', { productId });

export const GET_PRODUCT_IMAGES_URL = (productId) =>
  getOpsAPIURL('/products/:productId/get-images', { productId });

export const GET_UPLOAD_PRE_SIGNED_URL = getOpsAPIURL(
  '/aws/get-upload-pre-signed-url'
);

export const ADD_PRODUCT_IMAGE_URL = (productId) =>
  getOpsAPIURL('/products/:productId/productMedia', { productId });

export const GET_PROMOCODES_URL = ({ skip, limit, filters }) =>
  getOpsAPIURL('/promocodes?skip=:skip&limit=:limit&filters=:filters', {
    skip,
    limit,
    filters
  });

export const GET_PROMOCODE_URL = (promocodeId) =>
  getOpsAPIURL('/promocodes/:promocodeId', { promocodeId });

export const CHECK_MEDIA_ALREADY_EXIST_URL = getOpsAPIURL(
  '/aws/check-media-already-exist'
);

export const UPDATE_PROMOCODE_URL = (promocodeId) =>
  getOpsAPIURL('/promocodes/:promocodeId', { promocodeId });

export const CREATE_NEW_PRODUCT_IMAGE_URL = getOpsAPIURL('/products');

export const GET_COLLECTIONS_URL = ({ skip, limit, filters = {} }) =>
  getOpsAPIURL('/collections?skip=:skip&limit=:limit&filters=:filters', {
    skip,
    limit,
    filters
  });

export const GET_COLLECTION_BY_ID = (id) =>
  getOpsAPIURL('/collections/:id', { id });

export const UPDATE_COLLECTION = (id) =>
  getOpsAPIURL('/collections/:id', { id });

export const DELETE_COLLECTION_MEDIA = (id) =>
  getOpsAPIURL('/collections/:id/delete-media', { id });

export const GET_PRODUCTS_OF_A_COLLECTION = (skip, limit, id) =>
  getOpsAPIURL('/collections/:id/products?skip=:skip&limit=:limit', {
    id,
    skip,
    limit
  });

export const CREATE_PROMOCODE_URL = getOpsAPIURL('/promocodes');

export const GET_CATEGORY_SEO_DETAILS = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/seo-details', { categoryId });

export const GET_CATEGORY_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/id', { categoryId });

export const UPDATE_CATEGORY_BASIC_DETAILS_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId', { categoryId });

export const UPDATE_PRODUCT_SEO_DETAILS = (productId) =>
  getOpsAPIURL('/products/:productId/seo', { productId });

export const UPDATE_CATEGORY_SEO_DETAILS_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/seo-details', { categoryId });

export const UPDATE_CATEGORY_ICON_AND_IMAGE_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/icon-image', { categoryId });

export const GET_CATEGORY_ATTRIBUTES_GROUPS_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/categoryAttributesGroups', {
    categoryId
  });

export const ADD_COLLECTION = getOpsAPIURL('/collections');

export const ADD_PRODUCTS_TO_COLLECTION = (id) =>
  getOpsAPIURL('/collections/:id/bulk-products', { id });

export const DELETE_PRODUCTS_OF_COLLECTION = (id) =>
  getOpsAPIURL('/collections/:id/products', { id });

export const GET_CHILD_CATEGORIES_BY_PARENT_SLUG_URL = (parentSlug) =>
  getOpsAPIURL('/child-categories?parentSlug=:parentSlug', { parentSlug });

export const UPDATE_CATEGORIES_SORT_ORDER_URL = getOpsAPIURL(
  '/categories/update/sort-order'
);

export const GET_PUBLISHED_COLLECTIONS_URL = () =>
  getOpsAPIURL('/published-collections');

export const UPDATE_COLLECTIONS_SORT_ORDER_URL = getOpsAPIURL(
  '/collections/update/sort-order'
);

export const GET_ACTIVE_CATEGORY_ATTRIBUTES_GROUPS_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/activeCategoryAttributesGroups', {
    categoryId
  });

export const GET_TO_SELECT_ATTRIBUTES_URL = (attributeGroupId) =>
  getOpsAPIURL('/masterAttributesGroups/:attributeGroupId/attributesToAssign', {
    attributeGroupId
  });

export const GET_UNIT_LOOK_UPS_URL = getOpsAPIURL('/unit-look-ups');

export const GET_UNIT_VALUES_URL = (unitId) =>
  getOpsAPIURL('/unit-value/:unitId', { unitId });

export const CREATE_CATEGORY_ATTRIBUTES_GROUP_URL = (categoryId) =>
  getOpsAPIURL('/categories/:categoryId/assignCategoryAttributesGroups', {
    categoryId
  });

export const GET_CATEGORY_ATTRIBUTES_GROUP_URL = (
  categoryId,
  categoryAttributesGroupId
) =>
  getOpsAPIURL(
    '/categories/:categoryId/categoryAttributesGroups/:categoryAttributesGroupId',
    { categoryId, categoryAttributesGroupId }
  );

export const UPDATE_CATEGORY_ATTRIBUTE_GROUP_URL = (
  categoryId,
  categoryAttributesGroupId
) =>
  getOpsAPIURL(
    '/categories/:categoryId/categoryAttributeGroup/:categoryAttributesGroupId',
    { categoryId, categoryAttributesGroupId }
  );

export const CREATE_LEAFNODE_PRODUCT_DATA_EXPORT = getOpsAPIURL(
  '/leaf-node-product-data-exports'
);

export const GET_LEAFNODE_PRODUCT_DATA_EXPORTS = (skip, limit) =>
  getOpsAPIURL('/leaf-node-product-data-exports?skip=:skip&limit=:limit', {
    skip,
    limit
  });

export const GET_CITY_DETAILS = getOpsAPIURL('/cities');

export const UPDATE_PARTNER_CONTACT_DETAIL_URL = (partnerId) =>
  getOpsAPIURL('/update-partner-contact-detail/:partnerId', { partnerId });

export const UPDATE_PARTNER_PRODUCT_AND_SERVICE_URL = (partnerId) =>
  getOpsAPIURL('/update-partner-offering/:partnerId', { partnerId });

export const UPDATE_PARTNER_BANK_DETAIL_URL = (partnerId) =>
  getOpsAPIURL('/update-partner-bank-detail/:partnerId', { partnerId });

export const UPDATE_PARTNER_AGREEMENT_URL = (partnerId) =>
  getOpsAPIURL('/update-partner-agreement-and-commercial/:partnerId', {
    partnerId
  });

export const UPDATE_PARTNER_STATUTORY_DETAIL_URL = (partnerId) =>
  getOpsAPIURL('/update-partner-statutory/:partnerId', { partnerId });

export const UPDATE_CATEGORY_ATTRIBUTES_GROUP_SORT_ORDER_URL = getOpsAPIURL(
  '/category-attributes-group/update/sort-order'
);

export const GET_MASTER_ATTRIBUTES_GROUP_URL = (
  skip,
  limit,
  searchQuery = ''
) =>
  getOpsAPIURL(
    '/master-attributes-group?skip=:skip&limit=:limit&searchQuery=:searchQuery',
    { skip, limit, searchQuery }
  );

export const CREATE_MASTER_ATTRIBUTES_GROUP_URL = getOpsAPIURL(
  '/master-attributes-group'
);

export const GET_MASTER_ATTRIBUTE_GROUP_URL = (masterAttributeGroupId) =>
  getOpsAPIURL('/master-attributes-group/:masterAttributeGroupId', {
    masterAttributeGroupId
  });

export const GET_MASTER_ATTRIBUTES_URL = getOpsAPIURL('/master-attributes');

export const UPDATE_MASTER_ATTRIBUTE_GROUP_URL = (masterAttributeGroupId) =>
  getOpsAPIURL('/master-attributes-group/:masterAttributeGroupId', {
    masterAttributeGroupId
  });

export const GET_MASTER_ATTRIBUTES_WITH_ATTRIBUTE_GROUPS_URL = (
  skip,
  limit,
  searchQuery = ''
) =>
  getOpsAPIURL(
    '/master-attributes-with-attribute-groups?skip=:skip&limit=:limit&searchQuery=:searchQuery',
    { skip, limit, searchQuery }
  );

export const CREATE_MASTER_ATTRIBUTES_URL = getOpsAPIURL('/master-attributes');

export const GET_MASTER_ATTRIBUTE_URL = (masterAttributeId) =>
  getOpsAPIURL('/master-attribute/:masterAttributeId', {
    masterAttributeId
  });

export const UPDATE_MASTER_ATTRIBUTES_URL = (masterAttributeId) =>
  getOpsAPIURL('/master-attributes/:masterAttributeId', {
    masterAttributeId
  });

export const GET_PRODUCT_PARTNERS_URL = (productId) =>
  getOpsAPIURL('/products/:productId/partners', { productId });

export const CREATE_PRODUCT_PARTNER = (productId, partnerId) =>
  getOpsAPIURL(`/products/:productId/partners/:partnerId`, {
    productId,
    partnerId
  });

export const UPDATE_PRODUCT_PARTNER = (productPartnerId) =>
  getOpsAPIURL('/product-partners/:productPartnerId', { productPartnerId });

export const UPDATE_PRODUCT_PARTNER_RANK = (productId) =>
  getOpsAPIURL('/products/:productId/product-partners/rank', { productId });

export const GET_PRODUCT_PARTNER_LIST = (productId) =>
  getOpsAPIURL('/products/:productId/product-partners/list', { productId });

export const UPDATE_PRODUCT_ONE_TO_MANY_FLAG = (productId) =>
  getOpsAPIURL('/products/:productId/one-to-many', { productId });

export const GET_PRODUCT_ORDER_HISTORY = ({ productId, skip, limit }) =>
  getOpsAPIURL('/products/:productId/order-history?skip=:skip&limit=:limit', {
    productId,
    skip,
    limit
  });

export const GET_ORDER_BY_ID = (orderId) =>
  getOpsAPIURL('/orders/:orderId/carts', {
    orderId
  });

export const UPDATE_ORDER_BY_ID = (orderId) =>
  getOpsAPIURL('/order/:orderId', {
    orderId
  });

export const GET_ORDER_EVENT_BY_ID = (orderId) =>
  getOpsAPIURL('/orders/:orderId/events', {
    orderId
  });

export const UPDATE_ORDER_EVENT_BY_ID = (orderId) =>
  getOpsAPIURL('/orders/:orderId/usereventdetail', {
    orderId
  });

export const UPDATE_PAYMENT_REMARKS = (orderId, paymentId) =>
  getOpsAPIURL('/orders/:orderId/payments/:paymentId', {
    orderId,
    paymentId
  });

export const VOID_PAYMENT_BY_ID = (paymentId, orderId) =>
  getOpsAPIURL('/orders/:orderId/payments/:paymentId/void', {
    orderId,
    paymentId
  });

export const UPDATE_ORDER_ITEM = (orderId, orderItemId) =>
  getOpsAPIURL('/order/:orderId/orderItem/:orderItemId/details', {
    orderId,
    orderItemId
  });

export const GET_CART_LIST_URL = ({
  filters,
  limit,
  skip,
  sortField,
  sortOrder
}) =>
  getOpsAPIURL(
    '/cart/list?skip=:skip&limit=:limit&sortField=:sortField&sortOrder=:sortOrder&filters=:filters',
    {
      filters,
      limit,
      skip,
      sortField,
      sortOrder
    }
  );

export const GET_PAYMENT_BY_ORDER_ID = (orderId) =>
  getOpsAPIURL('/orders/:orderId/payments', {
    orderId
  });

export const ADD_PAYMENT_DETAIL = (orderId) =>
  getOpsAPIURL('/orders/:orderId/payment', {
    orderId
  });

export const GET_CHECKOUT_EVENTS_API_URL = getOpsAPIURL('/checkout/eventType');

export const GET_ORDER_INVOICE = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/invoice', { orderId });

export const GET_ORDER_DELIVERY_PARTNERS_BY_ORDER_ID = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/orderDeliveryPartners', {
    orderId
  });

export const UPDATE_ORDER_DELIVERY_PARTNER_BY_ID = ({
  orderId,
  orderDeliveryPartnerId
}) =>
  getOpsAPIURL(
    '/orders/:orderId/orderDeliveryPartner/:orderDeliveryPartnerId',
    { orderId, orderDeliveryPartnerId }
  );

export const POST_METABASE_DASHBOARD_SIGNED_URL = () =>
  getOpsAPIURL('/mb/generate-signed-url');

export const GET_ORDER_ITEM_DETAIL_BY_ORDER_ITEM_ID = ({
  orderId,
  orderItemId
}) =>
  getOpsAPIURL('/orders/:orderId/orderItem/:orderItemId', {
    orderId,
    orderItemId
  });

export const GET_IMAGEKIT_AUTH = () => getOpsAPIURL('/imagekit/auth');

export const RECONCILE_ORDER_MEDIA_FROM_IMAGEKIT = () =>
  getOpsAPIURL('/order-media/reconcile');

export const GET_ORDER_MEDIA = ({ orderId }) =>
  getOpsAPIURL('/order/:orderId/order-media', { orderId });

export const DELETE_ORDER_IMAGE = ({ orderId, orderMediaId }) =>
  getOpsAPIURL('/order/:orderId/order-media/:orderMediaId', {
    orderId,
    orderMediaId
  });

export const UPDATE_EVENT_IMAGE_SORT_ORDER = ({ orderId }) =>
  getOpsAPIURL('/order/:orderId/order-media/update-sort-order', {
    orderId
  });

export const UPDATE_ORDER_ITEMS_DELIVERY_PICKUP = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/orderItems/delivery-pickup', {
    orderId
  });

export const UPDATE_ORDER_STATUS = ({ orderId }) =>
  getOpsAPIURL('/order/:orderId/status', {
    orderId
  });

export const GET_HOST_SEGMENTS = () => getOpsAPIURL('/host-segments');

export const GET_COUNTRIES = () => getOpsAPIURL('/countries');

export const GET_SPECIALTY_EVENTS = () => getOpsAPIURL('/specialty-events');

export const GET_SPEND_TYPES = () => getOpsAPIURL('/spend-types');

export const GET_GUEST_COUNT_RANGES = () => getOpsAPIURL('/guest-count-ranges');

export const GET_EVENT_SITE_TYPES = () => getOpsAPIURL('/event-site-types');

export const UPDATE_ORDER_CONFIGURATION_WINDOW = () =>
  getOpsAPIURL('/orders/configuration/edit-window');
